import React from "react"
import Layout from "../components/layout"
import Seo from '../components/seo'
import { Link } from 'gatsby'

export default function TermsOfUse() {

  return <Layout>
    <Seo title="Terms of use" />
    <div className="container">
      <div className="basic-page-content">

        <h2>Terms of use</h2>

        <p className="small">Last updated: 18 April 2023</p>

        <p>This site is the property of Silta Finance AG (“Silta”, “we” or “us”), a Swiss limited company (reg. no. CHE-162.320.632). By using this site, you consent to the following Terms of Use.</p>

        <hr />
        <h2>1. Introduction</h2>

        <p>You are using the Silta website. This site is designed to provide information regarding the development of a Silta platform and related services and all users must abide by these Terms of Use ("Terms").</p>
        <p>You must be at least 18 years of age to sign up on the Silta platform to receive our newsletter, receive other information from us, or to use any other services from Silta ("Services"). Regardless of your age, you cannot sign up or use any of the Services if you are considered to be a minor in your country.</p>

        <p>Your use of the Services is subject to these Terms.</p>

        <hr />
        <h2>2. Use and access</h2>

        <p>In order to use any of the Services, you must have a valid Silta account. You are responsible for providing accurate registration information upon registering for a Silta account, and for keeping your registration information up to date or notifying us in the event of changes.</p>

        <p>You may request to close your Silta account at any time. After we receive your request to close the account, we will do so without delay as long as you have no outstanding obligations or a contractual relationship with us.</p>

        <p>If you violate these Terms or the terms of any contractual relationship with us, we may disable access to your Silta account at any time. If we disable access to your account, you may be prevented from accessing the Services or your account details. To the maximum extent permitted by applicable laws, Silta shall bear no liability for any expenses, losses, damages or any other costs incurred by you or any third party due to us disabling access to your Silta account.</p>

        <p>You are responsible for any access or data fees incurred from third parties (such as your Internet provider or mobile carrier) in connection with you visiting the Silta website, accessing the Silta platform, or your use of the Services.</p>

        <hr />
        <h2>3. Data protection</h2>

        <p>Our <Link to="/privacy-policy">privacy policy</Link> explains how we treat your personal data and protect your privacy when using the Services. Silta bears no responsibility or liability for any third parties privacy policies or their compliance with applicable legislation.</p>

        <p>You are responsible for keeping your account details secure and must not share them with anyone else. You may not collect or harvest any personal data of any user of the Services, including account names.</p>

        <hr />
        <h2>4. Content related to services</h2>

        <p>Silta may make data and information available to you on its website or as part of the Services ("Content"). Silta makes reasonable attempts to ensure the accuracy of Content, but it will not hold any responsibility for missing or wrong information. Content may originate from third parties, and it is essential that you conduct your own due diligence when referring to or making use of it. Silta provides all Content as is, and subject to the disclaimers set forth in section 7 below. You are using this site at your own risk.</p>

        <p>In certain cases (for example if a Service is discontinued, there are critical security issues, or there are breaches of applicable terms or the law), Silta may remove or cease providing you with access to Services or to related Content.</p>

        <hr />
        <h2>5. Rights and restrictions</h2>

        <p>After signing up and successfully creating a Silta account, you will have the non-exclusive right, solely as expressly permitted in these Terms, to use the Services on the Silta platform and to view Content. Any such access and use is subject to further terms and limitations set out in the terms of each specific Service.</p>

        <p>All rights, title and interest not expressly granted to you in the Terms or in the terms of a specific Service are reserved. Accessing the Silta platform or using the Services impart to you no right or license in Silta, the Silta platform, the Services or the Content.</p>

        <p>Furthermore, you may not:</p>

        <ol type="A">
          <li>use Content as part of any service for sharing, lending or multi-person use or for the purpose of any other institution;</li>
          <li>attempt to, or assist, authorise or encourage others to circumvent, disable or defeat any of the security features or components that protect, obfuscate or otherwise restrict access to any of the Services or any third-party services;</li>
          <li>copy, modify, publish, transmit, distribute, transfer or sell, or in any way exploit, any of the Content not submitted or provided by you;</li>
          <li>access the Services by any means other than through standard industry- accepted interfaces, or manipulate or otherwise display the Services by using framing or similar navigational technology;</li>
          <li>post or transmit any material that contains a malware, virus, worm, Trojan horse, or any other contaminating or destructive feature;</li>
          <li>use any of the communications features in the Services in a manner that adversely affects the availability of its resources to other users;</li>
          <li>post or transmit any unsolicited advertising, promotional materials, &quot;junk mail&quot;, &quot;spam&quot;, &quot;chain letters&quot;, &quot;pyramid schemes&quot; or any other form of solicitation;</li>
          <li>upload or transmit any material that infringes, violates or misappropriate any patent, trademark, service mark, trade secret, copyright or other proprietary rights of any third party or violates a third party&rsquo;s right of privacy or publicity, or that is defamatory or otherwise unlawful or that is intended for an unlawful use or application; or</li>
          <li>remove any watermarks, labels or other legal or proprietary notices included in any Content, or attempt to modify any Content obtained through the Services, including any modification for the purpose of disguising or changing any indications of the ownership or source of Content; or use the Site and/or the Services for any other purpose that is unlawful or prohibited by these Terms.</li>
        </ol>

        <p>If you violate these Terms, the terms of any specific Service, or the terms of any current or future contractual relationship with us, you immediately and automatically lose all rights under these Terms. In such a case, we may at any time terminate your access to the Silta platform and the Services without refund to you and without any liability towards you.</p>

        <p>We reserve the right to pursue legal recourse in the case that you breach the Terms, particularly those set out in this section 5.</p>

        <hr />
        <h2 id="siltaScoring">6. Silta Scoring</h2>
        <p>Silta conducts project assessment and scoring activities within its Services.  If you partake in such Services you will be required to consent to the Due Diligence Consent Letter which lays out our use of Content and additional information provided by you and/or third parties and sets forth the legal basis for Silta to conduct evaluation and scoring activities.  Silta reserves the right to decline any application for Services or Silta Scoring (as defined in the Due Diligence Consent Letter) in its sole discretion.</p>
        
        <hr />
        
        <h2 id="disclaimers">7. Disclaimers</h2>

        <p>Silta makes the following disclaimers in respect of our site, Service, Content and the development of our Silta platform in general:</p>

        <h3 className="h5">Accuracy of information</h3>

        <p>Silta makes reasonable attempts to ensure accuracy of the information and Content listed on this website although it will not hold any responsibility for any missing or wrong information. Some information and content may originate from third parties, and it is essential that you conduct your own due diligence when referring to or making use of any information, Content or Services provided on this site.</p>

        <p>This website is provided on an &quot;as is&quot; basis without any warranties of any kind regarding the website and/or any information, Content, data, materials and/or Services provided on this website or the website of any third-party referenced or linked herein. You understand that you are using this site and any and all information available here at your own risk.</p>

        <h3 className="h5">No investment advice</h3>

        <p>The information and Content provided on this site is for reference purposes only. Silta is not a financial advisor or investment advisor. The information and Content provided on this Silta website does not constitute financial advice, investment advice, trading advice, or any other sort of advice, and you should not treat any of the website's content as such.</p>

        <h3 className="h5">No endorsement</h3>

        <p>Silta does not make any endorsement, warranty, guarantee or recommendation about any third-party advertisements or hyperlinks on this site. Please conduct your own due diligence before deciding to use any third-party services.</p>

        <hr />
        <h2>8. Changes to the terms</h2>

        <p>Silta has the right to change these Terms at any time by uploading the revised Terms onto this website and by notifying you of the change on the Silta platform. By your continued use of the Services following such notice, you are accepting the new Terms. The new Terms will apply to your use of all Services and all related Content. For any questions regarding these terms, email us at <a href="mailto:contact@silta.finance">contact@silta.finance</a>.</p>
      </div>
    </div>
  </Layout>
}
